import Vue from 'vue';
import admin from './admin';
import app from './app';
import user from './user';
import setting from './setting';
import article from './article';
import console from './console';
import feedback from './feedback';

Vue.prototype.$api = {
  admin,
  app,
  user,
  setting,
  article,
  console,
  feedback,
};
