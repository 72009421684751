import service from '@/utils/service';

export default {
  // 列表
  list(params) {
    return service.get('/api/admin/feedback/list', { params });
  },
  // 处理
  process(data) {
    return service.post('/api/admin/feedback/process', data);
  },
};
