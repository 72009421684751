import service from '@/utils/service';

export default {
  // 登录
  login(data) {
    return service.post('/api/admin/login', data);
  },

  // 管理员列表
  list(params) {
    return service.get('/api/admin/admin-list', { params });
  },

  // 新增管理员
  add(data) {
    return service.post('/api/admin/add-admin', data);
  },

  // 编辑管理员
  edit(data) {
    return service.post('/api/admin/edit-admin', data);
  },

  // 禁用管理员
  disable(data) {
    return service.post('/api/admin/disable-admin', data);
  },

  // 启用管理员
  enable(data) {
    return service.post('/api/admin/enable-admin', data);
  },

  // 修改密码
  changePassword(data) {
    return service.post('/api/admin/change-password', data);
  }
};
