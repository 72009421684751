import Vue from 'vue';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import dayjs from 'dayjs';
import '@/api/index.js';
import App from './App.vue';
import router from '@/router/index.js';
import store from '@/store/index.js';
import VueQuillEditor from 'vue-quill-editor';
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
// import 'quill/dist/quill.bubble.css' // for bubble theme


Vue.config.productionTip = false;

Vue.use(Antd);
Vue.use(VueQuillEditor);
Vue.prototype.$dayjs = dayjs;

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
