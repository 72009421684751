import service from '@/utils/service';

export default {
  // 列表
  list(params) {
    return service.get('/api/admin/user/list', { params });
  },
  // 用户禁用、启用
  disableEnable(data) {
    return service.post('/api/admin/user/disable-enable', data);
  },
};
