import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index.js';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/login',
      component: () => import('@/views/Login/Index.vue'),
      meta: {
        auth: false,
      },
    },
    {
      path: '/',
      component: () => import('@/views/Admin/Index.vue'),
      redirect: '/console',
      children: [
        // 控制台
        {
          path: '/console',
          component: () => import('@/views/Console/Index.vue'),
          meta: {
            menuName: '控制台',
            icon: 'dashboard',
          },
        },
        // 文章
        {
          path: '/article',
          component: () => import('@/views/Article/Index.vue'),
          redirect: '/article/list',
          meta: {
            menuName: '文章',
            icon: 'read',
          },
          children: [
            {
              path: '/article/list',
              component: () => import('@/views/Article/List.vue'),
              meta: {
                menuName: '文章列表',
              },
            },
            {
              path: '/article/category',
              component: () => import('@/views/Article/Category.vue'),
              meta: {
                menuName: '分类管理',
              },
            },
            {
              path: '/article/detail',
              component: () => import('@/views/Article/Detail.vue'),
              meta: {
                showMenu: false,
                menuName: '文章详情',
              },
            },
            {
              path: '/article/add',
              component: () => import('@/views/Article/AddEdit.vue'),
              meta: {
                showMenu: false,
                menuName: '发布文章',
              },
            },
            {
              path: '/article/edit',
              component: () => import('@/views/Article/AddEdit.vue'),
              meta: {
                showMenu: false,
                menuName: '编辑文章',
              },
            },
            {
              path: '/article/review',
              component: () => import('@/views/Article/Detail.vue'),
              meta: {
                showMenu: false,
                menuName: '审核文章',
              },
            }
          ],
        },
        // 应用
        {
          path: '/app',
          component: () => import('@/views/App/Index.vue'),
          redirect: '/app/list',
          meta: {
            menuName: '应用',
            icon: 'appstore',
          },
          children: [
            {
              path: '/app/list',
              component: () => import('@/views/App/List.vue'),
              meta: {
                menuName: '应用列表',
              },
            },
            // 分类管理
            {
              path: '/app/category',
              component: () => import('@/views/App/Category.vue'),
              meta: {
                menuName: '分类管理',
              },
            },
            {
              path: '/app/detail',
              component: () => import('@/views/App/Detail.vue'),
              meta: {
                showMenu: false,
                menuName: '应用详情',
              },
            },
          ],
        },
        // 用户
        {
          path: '/user',
          component: () => import('@/views/User/Index.vue'),
          redirect: '/user/list',
          meta: {
            menuName: '用户',
            icon: 'smile',
          },
          children: [
            {
              path: '/user/list',
              component: () => import('@/views/User/List.vue'),
              meta: {
                menuName: '用户列表',
              },
            },
          ],
        },
        // 反馈
        {
          path: '/feedback',
          component: () => import('@/views/Feedback/Index.vue'),
          redirect: '/feedback/list',
          meta: {
            menuName: '反馈',
            icon: 'message',
          },
          children: [
            {
              path: '/feedback/list',
              component: () => import('@/views/Feedback/List.vue'),
              meta: {
                menuName: '反馈列表',
              },
            },
          ],
        },
        // 设置
        {
          path: '/setting',
          component: () => import('@/views/Setting/Index.vue'),
          redirect: '/setting/banner',
          meta: {
            menuName: '系统设置',
            icon: 'setting',
          },
          children: [
            {
              path: '/setting/banner',
              component: () => import('@/views/Setting/Banner.vue'),
              meta: {
                menuName: 'Banner设置',
              },
            },
            {
              path: '/setting/admin',
              component: () => import('@/views/Setting/Admin.vue'),
              meta: {
                menuName: '系统管理员',
                permission: ['SUPER_ADMIN'],
              },
            },
          ],
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  // console.log(
  //   to.meta.auth !== false && store.getters['user/_isLogin'],
  //   !to.meta.auth
  // );
  if (
    (to.meta.auth !== false && store.getters['user/_isLogin']) ||
    to.meta.auth === false
  ) {
    // if (process.env.NODE_ENV == 'production') {
    //   // 百度统计
    //   window._hmt.push(['_trackPageview', to.fullPath]);
    // }
    next();
  } else {
    next('/login');
  }
});

export default router;
