import service from '@/utils/service';

export default {
  // 列表
  list(params) {
    return service.get('/api/admin/article/list', { params });
  },
  // 详情
  detail(params) {
    return service.get('/api/admin/article/detail', { params });
  },
  // 审核
  review(data) {
    return service.post('/api/admin/article/review', data);
  },
  // 发布文章
  publish(data) {
    return service.post('/api/admin/article/publish', data);
  },
  // 撤回发布文章
  revoke(data) {
    return service.post('/api/admin/article/revoke', data);
  },
  // 保存文章
  save(data) {
    return service.post('/api/admin/article/save', data);
  },
  // 更新文章
  update(data) {
    return service.post('/api/admin/article/update', data);
  },

  // 分类列表
  categoryList(params) {
    return service.get('/api/admin/category/list', { params });
  },
  // 新增分类
  addCategory(data) {
    return service.post('/api/admin/category/save', data);
  },
  // 编辑分类
  editCategory(data) {
    return service.post('/api/admin/category/update', data);
  },
  // 删除分类
  deleteCategory(data) {
    return service.post('/api/admin/category/delete', data);
  },
};
