import service from '@/utils/service';

export default {
  // 面板数据
  pannelData(params) {
    return service.get('/api/admin/console/pannel-data', { params });
  },
  // 趋势图数据
  trendData(params) {
    return service.get('/api/admin/console/trend-data', { params });
  }
};
