const state = {
  token: '',
  userInfo: null,
};

const getters = {
  _userInfo: (state) => state.userInfo,
  _isLogin: (state) => !!state.token && !!state.userInfo,
};

const mutations = {
  setState(state, payload) {
    for (let key in payload) {
      state[key] = payload[key];
    }
  },
  logout(state) {
    state.token = '';
    state.userInfo = null;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
