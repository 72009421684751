import service from '@/utils/service';

export default {
  // 列表
  list(params) {
    return service.get('/api/admin/app/list', { params });
  },
  // 审核
  review(data) {
    return service.post('/api/admin/app/review', data);
  },
  // 应用分类列表
  categoryList(params) {
    return service.get('/api/admin/app/category/list', { params });
  },
  // 新增应用
  add(data) {
    return service.post('/api/admin/app/add', data, {
      timeout: 30000,
    });
  },
  // 上架
  publish(data) {
    return service.post('/api/admin/app/publish', data);
  },
  // 下架
  unpublish(data) {
    return service.post('/api/admin/app/unpublish', data);
  },
  // 应用分类
  category(params) {
    return service.get('/api/admin/app/category/list', { params });
  },
  // 新增应用分类
  addCategory(data) {
    return service.post('/api/admin/app/category/add', data);
  },
  // 编辑应用分类
  editCategory(data) {
    return service.post('/api/admin/app/category/update', data);
  },
  // 删除应用分类
  deleteCategory(data) {
    return service.post('/api/admin/app/category/delete', data);
  },
};
