import axios from 'axios';
// import { Toast } from 'vant';
import { message } from 'ant-design-vue';
import { ERROR_CODE } from '@/config/const.js';
// import Cookies from 'js-cookie';
import Store from '@/store/index.js';
import Router from '@/router/index.js';

// 创建一个独立的axios实例
const service = axios.create({
  // 设置baseUr地址,如果通过proxy跨域可直接填写base地址
  // baseURL: process.env.VUE_APP_API_BASE_URL,
  // 定义统一的请求头部
  // headers: {
  //   post: {
  //     'Content-Type': 'application/json;charset=UTF-8',
  //   },
  // },
  // 配置请求超时时间
  timeout: 10000,
});

// 请求拦截
service.interceptors.request.use((config) => {
  // 自定义header，可添加项目token
  // const token = Cookies.get(COOKIE_NAMES.USER_TOKEN);
  const token = Store.state.user.token;
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  // locale
  const locale = localStorage.getItem('locale');
  config.headers['Accept-Language'] = locale || 'en-US';
  // // 配置了loading菊花
  // if (config.isShowLoading) {
  //   Toast.loading({
  //     message: `Loading...`,
  //     forbidClick: true,
  //     duration: 0,
  //   });
  // }

  return config;
});
// 返回拦截
service.interceptors.response.use(
  (response) => {
    // 获取接口返回结果
    const res = response.data;
    // code为0，直接把结果返回回去，这样前端代码就不用在获取一次data.
    if (res.code === ERROR_CODE.SUCCESS) {
      // // 关闭菊花
      // if (response.config.isShowLoading) {
      //   Toast.clear();
      // }
      return { err: null, res };
    } else if (
      [
        ERROR_CODE.TOKEN_EXPIRE,
        ERROR_CODE.UNLOGIN,
        ERROR_CODE.USER_UNAVAILABLE,
      ].includes(res.code)
    ) {
      // 清除token
      Store.commit('user/setState', {
        token: '',
        userInfo: null,
      });
      // 10000假设是未登录状态码
      // Toast(res.msg);
      // 也可使用router进行跳转
      // window.location.href = "/login";
      Store.commit('common/setState', {
        loginPopupVisible: true,
      });
      Router.replace({
        path: '/login',
      });
      return res;
    } else {
      // 错误显示可在service中控制，因为某些场景我们不想要展示错误
      // Toast(res.msg);
      message.error(res.msg);
      return { err: res.msg, res };
    }
  },
  (error) => {
    // Toast('Request failed, please try again later');
    message.error('Request failed, please try again later');
    return { err: error };
  }
);

export default service;
