const state = {
  // loginPopupVisible: false,
};

const getters = {};

const mutations = {
  setState(state, payload) {
    for (let key in payload) {
      state[key] = payload[key];
    }
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
