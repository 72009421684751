export const COOKIE_NAMES = {
  USER_TOKEN: 'user_token',
};

// 错误码
export const ERROR_CODE = {
  SUCCESS: 0, // 成功
  NORMAL: 10000, // 普通错误
  UNKNOW: 10001, // 未知错误
  TOKEN_EXPIRE: 10002, // token 过期
  UNLOGIN: 10003, // 未登录
  USER_UNAVAILABLE: 10004, // 被禁用
  PI_NETWORK_SERVER_ERROR: 10086, // pi network 服务器报错
};

// 订单状态
export const ORDER_STATUS = {
  WAIT_PAY: {
    value: 0,
    text: 'Pending Payment',
  },
  PAY_SUCCESS: {
    value: 1,
    text: 'Payment Successful',
  },
  PAY_CANCEL: {
    value: 2,
    text: 'Canceled',
  },
};

// 明细类型
export const BALANCE_DETAILS_TYPE = {
  // 收入
  INCOME: {
    value: 0,
    text: 'Income',
  },
  // 支出
  EXPEND: {
    value: 1,
    text: 'Expend',
  },
};

// 明细原因
export const BALANCE_DETAILS_REASON = {
  // 交易
  TRANSACTION: {
    value: 0,
    text: 'Transaction',
  },
  // 提现
  WITHDRAW: {
    value: 1,
    text: 'Withdraw',
  },
  // 充值
  RECHARGE: {
    value: 2,
    text: 'Recharge',
  },
  // 捐赠
  DONATE: {
    value: 3,
    text: 'Donate',
  },
};

// 明细状态
export const BALANCE_DETAILS_STATUS = {
  // 未完成
  UNFINISH: {
    value: 0,
    text: 'Unfinished',
  },
  // 已完成
  FINISH: {
    value: 1,
    text: 'Finished',
  },
  // 已取消
  CANCEL: {
    value: 2,
    text: 'Canceled',
  },
};

// 支付方式
export const BALANCE_DETAILS_PAYMENT_TYPE = {
  // Pi钱包
  PI_WALLET: {
    value: 0,
    text: 'Pi Wallet',
  },
  // 余额
  BALANCE: {
    value: 1,
    text: 'Balance',
  },
};

// 环境变量
export const ENV = {
  development: {
    staticBase: 'http://127.0.0.1:8001',
    ossBase: 'http://127.0.0.1:8001/oss',
  },
  test: {
    staticBase: 'https://test.pimage.store',
    ossBase: 'https://test.pimage.store/oss',
  },
  production: {
    staticBase: 'https://pimage.store',
    ossBase: 'https://pimage.store/oss',
  },
};


// 申请状态
export const JOB_APPLY_STATUS = {
  // 未查看
  NOT_VIEWED: {
    label: 'notViewed',
    value: 0,
  },
  // 已查看
  VIEWED: {
    label: 'viewed',
    value: 1,
  },
  // 已录用
  HIRED: {
    label: 'hired',
    value: 2,
  },
  // 不予录用
  NOT_HIRED: {
    label: 'notHired',
    value: 3,
  },
}

// 应用审核状态
export const APP_REVIEW_STATUS = {
  // 审核中
  IN_REVIEW: {
    // label: 'inReview',
    label: '待审核',
    value: 0,
  },
  // 通过
  APPROVED: {
    // label: 'approved',
    label: '通过',
    value: 1,
  },
  // 未通过
  NOT_APPROVED: {
    // label: 'notApproved',
    label: '未通过',
    value: 2,
  },
};

// 应用分类
export const APP_CATEGORY = {
  // 游戏
  GAME: {
    label: 'Game',
    // label: 'game',
    value: 1,
  },
  // 商城
  MARKETPLACE: {
    label: 'Marketplace',
    // label: 'marketplace',
    value: 2,
  },
  // 社交媒体
  SOCIALMEDIA: {
    label: 'Social Media',
    // label: 'socialMedia',
    value: 3,
  },
  // 工具
  TOOL: {
    label: 'Tool',
    // label: 'tool',
    value: 4,
  },
  // 其他
  OTHER: {
    label: 'Other',
    // label: 'other',
    value: 0,
  },
};

// 权限
export const PERMISSION = {
  // 超级管理员
  SUPER_ADMIN: {
    label: '超级管理员',
    value: 100,
  },
  // 管理员
  ADMIN: {
    label: '管理员',
    value: 99,
  },
};

// 文章状态
export const ARTICLE_STATUS = {
  // 审核中
  IN_REVIEW: {
    label: '审核中',
    value: 0,
  },
  // 通过
  APPROVED: {
    label: '通过',
    value: 1,
  },
  // 未通过
  NOT_APPROVED: {
    label: '未通过',
    value: 2,
  },
}
