import service from '@/utils/service';

export default {
  // 详情
  info(params) {
    return service.get('/api/admin/setting', { params });
  },
  // 更新
  update(data) {
    return service.post('/api/admin/setting/update', data);
  },
  // 更新banner
  updateBanner(data) {
    return service.post('/api/admin/setting/update-banner', data);
  },
};
