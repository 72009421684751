import Vue from 'vue';
import Vuex from 'vuex';
import persistedState from 'vuex-persistedstate';
import user from '@/store/modules/user.js'
import common from '@/store/modules/common.js'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    common,
    user,
  },
  plugins: [
    persistedState({
      storage: window.localStorage
    })
  ]
});